<template>
  <div class="card">
    <DataTable
      :value="notificationsList"
      showGridlines
      responsiveLayout="stack"
      breakpoint="768px"
      class="p-datatable-sm p-2 text-sm"
      contextMenu
      v-model:contextMenuSelection="selectedItem"
      @rowContextmenu="onRowContextMenu"
    >
      <Column field="NotificationPeople" header="Usuario" style="font-size: 0.875rem !important; text-transform: capitalize !important;" class="">
        <template #body="{data}">
          <p class="camelCase">
            {{ data.NotificationPeople.namePeople.toLowerCase() }}
          </p>
        </template>
      </Column>
      <Column field="email" header="Email" style="font-size: 0.875rem !important;"/>
      <Column field="phone" header="Telefono" style="font-size: 0.875rem !important;"/>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.notificaciones.access')" header="Opciones" headerStyle=" text-align: center" bodyStyle="text-align: center; overflow: visible" style="font-size: 0.875rem !important;">
        <template #body="{data}">
          <Button icon="pi pi-pencil" title="Actualizar" class="p-button-rounded p-button-warning p-mr-2" @click="viewProduct(data)" />
          <Button icon="pi pi-trash" title="Eliminar" class="p-button-rounded p-button-danger" @click="deleteProduct(data)"/>
        </template>
      </Column>
    </DataTable>
    <ContextMenu :model="Butonitems" ref="contextMenu" />
    <modalNotifications ref="modalNotifications"/>
  </div>
</template>
<script>
import { ref, onMounted, defineAsyncComponent } from 'vue'
import { useDataTable } from './useDataTable'
import { messageWarningDelete } from '@/libs/mensajes'
import ApiDeleteNotifications from '@/apps/pharmasan/accounting/amortizations/services/deteleNotifications'
import { useToast } from 'primevue/usetoast'
export default {
  name: 'dataTable',
  components: {
    modalNotifications: defineAsyncComponent(() => import('../modal/modal'))
  },
  setup () {
    const toast = useToast()
    const { getNotificationsList, notificationsList } = useDataTable()
    // data
    const contextMenu = ref()
    const selectedItem = ref()
    const Butonitems = [
      {
        label: 'Actualizar',
        icon: 'pi pi-fw pi-pencil',
        command: () => viewProduct(selectedItem.value)
      },
      {
        label: 'Eliminar',
        icon: 'pi pi-fw pi-times',
        command: () => deleteProduct(selectedItem.value)
      }
    ]
    const modalNotifications = ref()
    // hooks
    onMounted(() => {
      getNotificationsList()
    })
    // methods
    const onRowContextMenu = (event) => contextMenu.value.show(event.originalEvent)
    const viewProduct = (row) => {
      modalNotifications.value.toggle(1)
      modalNotifications.value.defineUserNotification(row)
    }
    const deleteProduct = async (row) => {
      const { isConfirmed } = await messageWarningDelete('', 'Eliminar registro', 'warning', '¿Esta seguro de eliminar el registro')
      if (isConfirmed) handleRemoveItemList(row.id)
    }
    const handleRemoveItemList = async (id) => {
      const { data } = ApiDeleteNotifications(id).catch(err => console.error(err))
      if (data) toast.add({ severity: 'success', summary: 'Registro Eliminado', detail: 'Eliminado con exíto', life: 3000 })
    }
    // return
    return {
      notificationsList,
      Butonitems,
      contextMenu,
      selectedItem,
      onRowContextMenu,
      deleteProduct,
      viewProduct,
      modalNotifications
    }
  }
}
</script>

<style scoped>
.camelCase p {
  text-transform: capitalize;
}
.firstLetter p {
  text-transform: uppercase;
}

.firstLetter p:first-letter {
  text-transform: uppercase;
}
</style>
